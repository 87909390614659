import React, {Component} from 'react';

import './App.css';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      verbose: true,
      enable_require: true,
      enable_steps: false,
      // Error response when record exists on endpoint
      duplicate: false,
      // enable_input: true,
      // endpoint_url: process.env.REACT_APP_API_URL,
      endpoint_url: "https://portal.aeroflow.online/",
      endpoint_leads: "api/leads/",
      endpoint_insurance_types: "management/leads/breastpumps_payer_lookup",
      // endpoint_token: "f9475d4bd5902b2b57405890d0822de97e565963",
      endpoint_user: "pregnant_chicken",
      endpoint_pass: ";hOJ@fOhXs",

      // form_errors: {
      //   endpoint_lead_state_selected: false,
      //   endpoint_lead_insurance_type_selected: false,
      // },

      steps: {
        current: 0,
        max: 0,
        s0: {
          submitted: false,
        },
        s1: {
          submitted: false,
        },
        s2: {
          submitted: false,
        },
      },

      endpoint_lead_states: states,
      endpoint_lead_state_selected: "",

      endpoint_lead_insurance_types_response: endpoint_lead_insurance_types_response,
      endpoint_lead_insurance_type_selected: {},

      // prod
      endpoint_lead_request: {
        submitted: false,
        email: "",
        due_date: "",
        due_date_1: "",
        due_date_2: "",
        due_date_3: "",
        fname: "",
        lname: "",
        address: "",
        city: "",
        state: "",
        zip_code: "",
        phone: "",
        phone_1: "",
        phone_2: "",
        phone_3: "",
        dob: "",
        dob_1: "",
        dob_2: "",
        dob_3: "",
        primary_provider: "",
        insurance_policy_num: "",
      },

      // test
      // endpoint_lead_request: {
      //   submitted: false,
      //   email: "tester@selenium.com",
      //   due_date: "",
      //   due_date_1: "02",
      //   due_date_2: "01",
      //   due_date_3: "2023",
      //   fname: "Selenium",
      //   lname: "Tester",
      //   address: "123 Fake st",
      //   city: "San Francisco",
      //   state: "",
      //   zip_code: "00000",
      //   phone: "",
      //   phone_1: "707",
      //   phone_2: "000",
      //   phone_3: "0000",
      //   dob: "",
      //   dob_1: "02",
      //   dob_2: "01",
      //   dob_3: "1990",
      //   primary_provider: "",
      //   insurance_policy_num: "000000",
      // },

      endpoint_lead_request_validity: {
        email: false,
        due_date: false,
        fname: true,
        lname: false,
        address: false,
        city: false,
        state: false,
        zip_code: false,
        phone: false,
        dob: false,
        primary_provider: false,
        insurance_policy_num: false,
        // referral_source_id:
      },

      endpoint_lead_response: {
        success: null,
        id: null,
        // email: "",
        due_date: "",
        // due_date_1: "",
        // due_date_2: "",
        // due_date_3: "",
        fname: "",
        lname: "",
        // address: "",
        // city: "",
        // state: "",
        // zip_code: "",
        phone: "",
        // phone_1: "",
        // phone_2: "",
        // phone_3: "",
        dob: "",
        // dob_1: "",
        // dob_2: "",
        // dob_3: "",
        // primary_provider: "",
        // insurance_policy_num: "",
        // referral_source_id: "",
      },

      endpoint_lead_error: {
        email: false,
        due_date: false,
        fname: false,
        lname: false,
        address: false,
        city: false,
        state: false,
        zip_code: false,
        phone: false,
        dob: false,
        primary_provider: false,
        insurance_policy_num: false,
        // referral_source_id: false,
      },
    }

    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleInsuranceCompaniesChange = this.handleInsuranceCompaniesChange.bind(this);
    this.handleContactKeyUp = this.handleContactKeyUp.bind(this);
    this.handleContactKeyDown = this.handleContactKeyDown.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.nextButton = this.nextButton.bind(this);
    this.submitStep = this.submitStep.bind(this);
  };

  componentDidMount() {
    this.log("Submitting to", this.state.endpoint_url);
  }

  log(...args) {
    if (this.state.verbose) {
      return console.log(...args);
    }
  }

  handleStateChange(e) {
    const el = e.target;
    this.log(el.name, el.value);
    this.setState({
      endpoint_lead_state_selected: el.value,
      endpoint_lead_request: {
        ...this.state.endpoint_lead_request,
        state: el.value,
      },
      endpoint_lead_response: {
        ...this.state.endpoint_lead_response,
        [el.id]: "",
      },
      endpoint_lead_insurance_types_response: endpoint_lead_insurance_types_response,
      endpoint_lead_insurance_type_selected: {},
    });
    fetch(this.state.endpoint_url + this.state.endpoint_insurance_types + '?' + new URLSearchParams({
      // group_id: 3,
      state: el.value,
    })).then(r => {
      return r.json();
    }).then(r => {
      if (typeof r.errors !== "undefined") {
        this.log("error:", r.errors);
      }
      this.log(r);
      this.setState({
        endpoint_lead_insurance_types_response: r,
      });
    }).catch(error => {
      if (error.name === 'AbortError')
        return;
      throw error;
    });
  }

  handleInsuranceCompaniesChange(e) {
    const el = e.target
    this.setState({
      endpoint_lead_insurance_type_selected: el.value,
      endpoint_lead_request: {
        ...this.state.endpoint_lead_request,
        primary_provider: el.value,
      },
      endpoint_lead_response: {
        ...this.state.endpoint_lead_response,
        [el.id]: "",
      }
    });
    this.log("id: ", el.id, "name: ", el.name, "value: ", el.value);
  }

  handleContactKeyDown(e) {
    this.log("---------------------------------------------")
    this.log("handleContactKeyDown")
    const el = e.target;

    const keyCode = e.keyCode;
    this.log("keyCode", keyCode);

    const selStart = el.selectionStart;
    this.log("selStart", selStart);

    const selEnd = el.selectionEnd;
    this.log("selEnd", selEnd);

    const maxLength = el.getAttribute("maxLength");
    this.log("maxLength", maxLength);

    const maxJumpPrev = el.getAttribute("max_jump_prev");
    this.log("maxJumpPrev", maxJumpPrev);

    const prevEl = document.getElementById(maxJumpPrev);

    const maxJumpNext = el.getAttribute("max_jump");
    this.log("maxJumpNext", maxJumpNext);

    // const nextEl = document.getElementById(maxJumpNext);

    if (
      e.keyCode === 8 &&
      selStart === 0 &&
      selEnd === 0
    ) {
      if (prevEl !== null) {
        this.log("handleContactKeyDown: prevEl.focus();")
        // prevEl.selectionStart = maxLength;
        // prevEl.selectionEnd = maxLength;
        prevEl.focus();
      }
    }
  }

  handleContactKeyUp(e) {
    this.log("---------------------------------------------")
    this.log("handleContactKeyUp");
    const el = e.target;

    const keyCode = e.keyCode;
    this.log("keyCode", keyCode);

    const selStart = el.selectionStart;
    this.log("selStart", selStart);

    const selEnd = el.selectionEnd;
    this.log("selEnd", selEnd);

    const maxLength = el.getAttribute("maxLength");
    this.log("maxLength", maxLength);

    const maxJumpPrev = el.getAttribute("max_jump_prev");
    this.log("maxJumpPrev", maxJumpPrev);

    const prevEl = document.getElementById(maxJumpPrev);

    const maxJumpNext = el.getAttribute("max_jump");
    this.log("maxJumpNext", maxJumpNext);

    const nextEl = document.getElementById(maxJumpNext);

    // Previous
    if (
      maxJumpPrev !== null &&
      (
        e.keyCode === 37 ||
        (
          e.keyCode === 8 &&
          el.value.length === 0
        )
      ) &&
      selStart.toString() === "0" &&
      selEnd.toString() === "0"
    ) {
      this.log("Previous", maxLength);
      if (prevEl !== null) {
        this.log("handleContactKeyUp: Previous: prevEl.focus();");
        // prevEl.selectionStart = maxLength;
        // prevEl.selectionEnd = maxLength;
        prevEl.focus();
        return
      }
    }

    // Next
    if (
      maxJumpNext !== null &&
      e.keyCode === 39  &&
      (
        el.value.length === 0 ||
        (
          el.selectionStart.toString() === maxLength &&
          el.selectionEnd.toString() === maxLength
        )
      )
    ) {
      this.log("Next", 0);
      if (nextEl !== null) {
        this.log("handleContactKeyUp: Next: nextEl.focus();");
        nextEl.selectionStart = 0;
        nextEl.selectionEnd = 0;
        nextEl.focus();
        return
      }
    }

    if (
      el.value.length.toString() === maxLength &&
      selStart.toString() === maxLength &&
      selEnd.toString() === maxLength
    ) {
      this.log("Next other", 0);
      if (nextEl !== null) {
        this.log("handleContactKeyUp: Other: nextEl.focus();");
        nextEl.selectionStart = 0;
        nextEl.selectionEnd = 0;
        nextEl.focus();
      }
    }
  }

  handleContactChange(e) {
    const el = e.target;
    this.log("handleContactChange", el.id);
    const validationType = el.getAttribute("validation_type");
    // const maxJump = el.getAttribute("max_jump");
    var validationRegex;
    if (validationType !== null) {
      this.log("validation_type", validationType);
      switch (validationType) {
        case "digits":
          validationRegex = /\D/g;
          break;
        case "date":
          validationRegex = /[^0-9-{1}]/g;
          break;
        default:
          break;
      }
      if (validationRegex !== null) {
        const newValue = el.value.replace(validationRegex, '');
        this.log(el.value, "->", newValue);
        if (el.value !== newValue) {
          el.value = newValue;
        }
      }
    }
    this.setState({
      ...this.state,
      endpoint_lead_request: {
        ...this.state.endpoint_lead_request,
        [el.id]: el.value
      },

      endpoint_lead_response: {
        ...this.state.endpoint_lead_response,
        [el.id]: "",
      },

      endpoint_lead_request_validity: {
        ...this.state.endpoint_lead_request_validity,
        [el.id]: this.validate(el),
      }
    });
    this.log(el.id, el.value);
  }

  validateString(s) {
    return s !== "";
  }

  validateEmail(s) {
    const re = /\S+@\S+\.\S+/;
    return s !== "" && re.test(s);
  }

  validatePhoneNumber(s) {
    const re = /\d/g;
    const m = s.match(re);
    if (m) {
      const l = m.length;
      return s !== "" && (l === 10 || l === 11);
    } else {
      return false;
    }
  }

  validateDate(s) {
    const re = /^\d{4}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$/
    return s !== "" && re.test(s);
  }

  validate(el) {
    var pass = true;
    switch(el.type) {
      case "text":
        pass = this.validateString(el.value);
        break;
      case "email":
        pass = this.validateEmail(el.value);
        break;
      case "tel":
        pass = this.validatePhoneNumber(el.value);
        break;
      case "date":
        pass = this.validateDate(el.value);
        break;
      default:
        break;
    }
    return pass;
  }

  responseError(r) {
    // this.log("responseError", r);
    var cr = ((typeof r === "undefined") || r === null || r === "");
    // this.log("responseError result", cr);
    return !cr;
  }

  handleSubmit(e) {
    this.setState({
      endpoint_lead_request: {
        ...this.state.endpoint_lead_request,
        submitted: true,
      }
    })
    this.log("handleSubmit on step", this.state.steps.max);

    var payload = this.state.endpoint_lead_request;

    // Phone
    payload.phone = `${this.state.endpoint_lead_request.phone_1}${this.state.endpoint_lead_request.phone_2}${this.state.endpoint_lead_request.phone_3}`;
    this.log("payload.phone", payload.phone);

    // DOB
    payload.dob = `${this.state.endpoint_lead_request.dob_3}-${this.state.endpoint_lead_request.dob_1}-${this.state.endpoint_lead_request.dob_2}`;
    this.log("payload.dob", payload.dob);

    // Due Date
    payload.due_date = `${this.state.endpoint_lead_request.due_date_3}-${this.state.endpoint_lead_request.due_date_1}-${this.state.endpoint_lead_request.due_date_2}`;
    this.log("payload.due_date", payload.due_date);

    if (this.state.enable_steps && this.state.steps.max < 1) {
      this.log("Submitting step: ", this.state.steps.current);
      this.submitStep(this.state.steps.current+1);
      e.preventDefault();
      return;
    }

    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(this.state.endpoint_user+':'+this.state.endpoint_pass),
    };

    this.log("headers: ", headers);

    var url = this.state.endpoint_url + this.state.endpoint_leads;

    this.log("Submitting to endpoint: ", url);
    this.log("Payload: ", payload);

    var rStatus;

    fetch(url, {
      method: 'POST',
      // credentials: 'include',
      headers: new Headers(headers),
      body: JSON.stringify(payload),
    }).then(r => {
      rStatus = r.ok;
      return r.json();
    }).then(r => {
      r.success = rStatus;
      this.log("Response", r);
      this.log("Response status ok", rStatus);
      this.setState({
        endpoint_lead_response: r,
      });
      if (typeof r.message !== "undefined") {
        this.setState({
          endpoint_lead_response: {
            ...this.state.endpoint_lead_response,
            success: rStatus,
            message: r.message,
          },
        });
      }
      if (typeof r.errors !== "undefined") {
        this.setState({
          enable_steps: false,
        });
        for (const [key, values] of Object.entries(r.errors)) {
          values.forEach(value => {
            var error_type = "";
            if (value.includes("required")) {
              error_type = "required";
            } else if (value.includes("invalid")) {
              error_type = "invalid";
            }
            this.setState({
              endpoint_lead_response: {
                ...this.state.endpoint_lead_response,
                [key]: error_type !== "" ? error_type : value
              }
            });
          });
        }
      }
    }).catch(error => {
      this.log("Catch error", error);
      this.setState({
        enable_steps: false,
      });
      this.log(false);
      if (error.name === 'AbortError')
        return;
      throw error;
    });
    e.preventDefault();
  }

  inputStates() {
    return (
      <label>
        State
        <select
          id="state"
          name="endpoint_lead_state_selected"
          required={this.state.enable_require}
          // className={(this.state.endpoint_lead_response["state"] !== "") ? 'invalid' : ''}
          value={this.state.endpoint_lead_state_selected}
          onChange={this.handleStateChange}
          >
          <option key="" value="">Select your state</option>
          {this.state.endpoint_lead_states.map((option) => (
            <option key={"state-" + option.code} value={option.code}>{option.name}</option>
          ))}
        </select>
      </label>
    )
  }

  inputInsuranceCompanies() {
    return (
      this.state.endpoint_lead_state_selected !== "" ?
        <label>
          Insurance Type
          <select
            id="primary_provider"
            name="endpoint_lead_insurance_type_selected"
            required={this.state.enable_require}
            // className={(this.state.endpoint_lead_response["primary_provider"] !== "") ? 'invalid' : ''}
            value={this.state.endpoint_lead_insurance_type_selected}
            onChange={this.handleInsuranceCompaniesChange}
            >
            <option key="" value="">Select your insurance type</option>
            {this.state.endpoint_lead_insurance_types_response.results.map((option) => (
              <option key={"insurance_companies-" + option.payer} value={option.payer}>{option.payer}</option>
            ))}
          </select>
        </label>
      : ""
    )
  }

  nextButton(si) {
    if (this.state.enable_steps) {
      return (
        <div
          className="Button Next"
          onClick={() => this.submitStep(si)}
        >
          Next
        </div>
      )
    }
  }

  submitStep(si) {
    // Validation
    this.setState({
      steps: {
        ...this.state.steps,
        [`s${si-1}`]: {
          submitted: true
        }
      },
    });
    const requiredFields = document.querySelectorAll(`.Step.S${si-1} input[required]`);
    var pass = true;
    this.log("----------------------------------------------------");
    requiredFields.forEach(field => {
      if (pass) {
        pass = this.state.endpoint_lead_request_validity[field.id];
        this.log(field.id, pass);
      }
    });
    if (!pass) {
      return;
    }
    this.log("Setting state");
    this.setState({
      steps: {
        ...this.state.steps,
        current: si,
        max: this.state.steps.max < si ? si : this.state.steps.max,
      },
    });
    window.location=`#S${si}`;
  }

  currentStep(si) {
    return !this.state.enable_steps || this.state.steps.current === si ? `Step S${si} Active` : `Step S${si}`;
  }

  stepStatus(si) {
    return (
      si === this.state.steps.current ?
        "StepBreadcrumb Active Current"
      : "StepBreadcrumb Active"
    )
  }

  breadcrumb(si) {
    return (
      this.state.steps.max >= si ?
        <div onClick={() => this.submitStep(si)} className={this.stepStatus(si)}>{si}</div>
      : <div className="StepBreadcrumb">{si}</div>
    )
  }

  render() {
    return (
      <div className="App com-pregnantchicken-aeroflow">
        <div id="S1"></div>
        <div id="S2"></div>
        {this.state.duplicate ?
          <div className="error">This information has been submitted before.</div>
        : ""}
        {this.state.enable_steps && this.state.steps.max > 0 && !this.state.endpoint_lead_response.success ?
          <div className="StepBreadcrumbs">
            {this.breadcrumb(1)}
            {this.breadcrumb(2)}
          </div>
        : ""}
        {this.state.endpoint_lead_response.success !== true ?
          <form onSubmit={this.handleSubmit}>
            <div className={this.currentStep(0)}>
              <div>
                {this.inputStates()}
              </div>
              <div>
                {this.inputInsuranceCompanies()}
              </div>
            <label>
                Email Address
                <input
                  id="email"
                  name="email"
                  required={this.state.enable_require}
                  className={(this.state.steps.s1.submitted && !this.validateEmail(this.state.endpoint_lead_request.email)) || (this.state.endpoint_lead_request.submitted && this.responseError(this.state.endpoint_lead_response.email)) ? 'invalid' : ''}
                  type="email"
                  value={this.state.endpoint_lead_request.email}
                  onChange={this.handleContactChange}
                />
              </label>
              <label>
                Phone Number
                { this.state.endpoint_lead_error.phone ?
                  <div className="inputErrorMessage">
                    Please only enter numbers
                  </div>
                : "" }
                <div className={this.state.steps.s1.submitted || this.responseError(this.state.endpoint_lead_response.phone) ? 'invalid combinedFields' : 'combinedFields'}>
                  (
                  <input
                    id="phone_1"
                    name="phone_1"
                    type="number"
                    className="phone_1"
                    required={this.state.enable_require}
                    value={this.state.endpoint_lead_request.phone_1}
                    onChange={this.handleContactChange}
                    validation_type="digits"
                    onKeyUp={this.handleContactKeyUp}
                    onKeyDown={this.handleContactKeyDown}
                    max_jump="phone_2"
                    maxLength={3}
                  />
                  )
                  <input
                    id="phone_2"
                    name="phone_2"
                    type="number"
                    className="phone_2"
                    required={this.state.enable_require}
                    value={this.state.endpoint_lead_request.phone_2}
                    onChange={this.handleContactChange}
                    validation_type="digits"
                    onKeyUp={this.handleContactKeyUp}
                    onKeyDown={this.handleContactKeyDown}
                    max_jump_prev="phone_1"
                    max_jump="phone_3"
                    maxLength={3}
                  />
                  -
                  <input
                    id="phone_3"
                    name="phone_3"
                    type="number"
                    className="phone_3"
                    required={this.state.enable_require}
                    value={this.state.endpoint_lead_request.phone_3}
                    onChange={this.handleContactChange}
                    validation_type="digits"
                    onKeyUp={this.handleContactKeyUp}
                    onKeyDown={this.handleContactKeyDown}
                    max_jump_prev="phone_2"
                    maxLength={4}
                  />
                </div>
              </label>
              <label>
                Due Date
                { this.state.endpoint_lead_error.due_date ?
                  <div className="inputErrorMessage">
                    Please only enter numbers and hyphens
                  </div>
                : "" }
                <div className={(this.state.steps.s1.submitted && !this.validateDate(this.state.endpoint_lead_request.dob)) || this.responseError(this.state.endpoint_lead_response.dob) ? 'invalid combinedFields' : 'combinedFields'}>
                  <input
                    id="due_date_1"
                    name="due_date_1"
                    type="number"
                    className="date_1"
                    placeholder="mm"
                    required={this.state.enable_require}
                    value={this.state.endpoint_lead_request.due_date_1}
                    onChange={this.handleContactChange}
                    validation_type="digits"
                    onKeyUp={this.handleContactKeyUp}
                    onKeyDown={this.handleContactKeyDown}
                    max_jump="due_date_2"
                    maxLength={2}
                  />
                  /
                  <input
                    id="due_date_2"
                    name="due_date_2"
                    type="number"
                    className="date_2"
                    placeholder="dd"
                    required={this.state.enable_require}
                    value={this.state.endpoint_lead_request.due_date_2}
                    onChange={this.handleContactChange}
                    validation_type="digits"
                    onKeyUp={this.handleContactKeyUp}
                    onKeyDown={this.handleContactKeyDown}
                    max_jump_prev="due_date_1"
                    max_jump="due_date_3"
                    maxLength={2}
                  />
                  /
                  <input
                    id="due_date_3"
                    name="due_date_3"
                    type="number"
                    className="date_3"
                    placeholder="yyyy"
                    required={this.state.enable_require}
                    value={this.state.endpoint_lead_request.due_date_3}
                    onChange={this.handleContactChange}
                    validation_type="digits"
                    onKeyUp={this.handleContactKeyUp}
                    onKeyDown={this.handleContactKeyDown}
                    max_jump_prev="due_date_2"
                    maxLength={4}
                  />
                </div>
              </label>
              <label>
                First Name
                <input
                  id="fname"
                  name="fname"
                  required={this.state.enable_require}
                  className={(this.state.steps.s1.submitted && !this.validateString(this.state.endpoint_lead_request.fname)) || this.responseError(this.state.endpoint_lead_response.fname) ? 'invalid' : ''}
                  type="text"
                  value={this.state.endpoint_lead_request.fname}
                  onChange={this.handleContactChange}
                />
              </label>
              <label>
                Last Name
                <input
                  id="lname"
                  name="lname"
                  required={this.state.enable_require}
                  className={(this.state.steps.s1.submitted && !this.validateString(this.state.endpoint_lead_request.lname)) || this.responseError(this.state.endpoint_lead_response.lname) ? 'invalid' : ''}
                  type="text"
                  value={this.state.endpoint_lead_request.lname}
                  onChange={this.handleContactChange}
                />
              </label>
              <label>
                Date of Birth
                { this.state.endpoint_lead_error.dob ?
                  <div className="inputErrorMessage">
                    Please only enter numbers and hyphens
                  </div>
                : "" }
                <div className={(this.state.steps.s1.submitted && !this.validateDate(this.state.endpoint_lead_request.dob)) || this.responseError(this.state.endpoint_lead_response.dob) ? 'invalid combinedFields' : 'combinedFields'}>
                  <input
                    id="dob_1"
                    name="dob_1"
                    type="number"
                    className="date_1"
                    placeholder="mm"
                    required={this.state.enable_require}
                    value={this.state.endpoint_lead_request.dob_1}
                    onChange={this.handleContactChange}
                    validation_type="digits"
                    onKeyUp={this.handleContactKeyUp}
                    onKeyDown={this.handleContactKeyDown}
                    max_jump="dob_2"
                    maxLength={2}
                  />
                  /
                  <input
                    id="dob_2"
                    name="dob_2"
                    type="number"
                    className="date_2"
                    placeholder="dd"
                    required={this.state.enable_require}
                    value={this.state.endpoint_lead_request.dob_2}
                    onChange={this.handleContactChange}
                    validation_type="digits"
                    onKeyUp={this.handleContactKeyUp}
                    onKeyDown={this.handleContactKeyDown}
                    max_jump_prev="dob_1"
                    max_jump="dob_3"
                    maxLength={2}
                  />
                  /
                  <input
                    id="dob_3"
                    name="dob_3"
                    type="number"
                    className="date_3"
                    placeholder="yyyy"
                    required={this.state.enable_require}
                    value={this.state.endpoint_lead_request.dob_3}
                    onChange={this.handleContactChange}
                    validation_type="digits"
                    onKeyUp={this.handleContactKeyUp}
                    onKeyDown={this.handleContactKeyDown}
                    max_jump_prev="dob_2"
                    maxLength={4}
                  />
                </div>
              </label>
              <label>
                Address
                { this.state.endpoint_lead_error.address ?
                  <div className="inputErrorMessage">
                    Please only enter numbers
                  </div>
                : "" }
                <input
                  id="address"
                  name="address"
                  required={this.state.enable_require}
                  className={(this.state.steps.s1.submitted && !this.validateString(this.state.endpoint_lead_request.address)) || this.responseError(this.state.endpoint_lead_response["address"]) ? 'invalid' : ''}
                  type="text"
                  value={this.state.endpoint_lead_request.address}
                  onChange={this.handleContactChange}
                />
              </label>
              <label>
                City
                { this.state.endpoint_lead_error.city ?
                  <div className="inputErrorMessage">
                    Please only enter numbers
                  </div>
                : "" }
                <input
                  id="city"
                  name="city"
                  required={this.state.enable_require}
                  className={(this.state.steps.s1.submitted && !this.validateString(this.state.endpoint_lead_request.city)) || this.responseError(this.state.endpoint_lead_response["city"]) ? 'invalid' : ''}
                  type="text"
                  value={this.state.endpoint_lead_request.city}
                  onChange={this.handleContactChange}
                />
              </label>
              <label>
                Zip Code
                { this.state.endpoint_lead_error.zip_code ?
                  <div className="inputErrorMessage">
                    Please only enter numbers
                  </div>
                : "" }
                <input
                  id="zip_code"
                  name="zip_code"
                  type="number"
                  required={this.state.enable_require}
                  className={(this.state.steps.s1.submitted && !this.validateString(this.state.endpoint_lead_request.zip_code)) || this.responseError(this.state.endpoint_lead_response["zip_code"]) ? 'invalid' : ''}
                  value={this.state.endpoint_lead_request.zip_code}
                  onChange={this.handleContactChange}
                  validation_type="digits"
                />
              </label>
              <label>
                Insurance Member ID
                <input
                  id="insurance_policy_num"
                  name="identification_number"
                  required={this.state.enable_require}
                  className={(this.state.steps.s1.submitted && !this.validateString(this.state.endpoint_lead_request.insurance_policy_num)) || this.responseError(this.state.endpoint_lead_response["insurance_policy_num"]) ? 'invalid' : ''}
                  type="text"
                  value={this.state.endpoint_lead_request.insurance_policy_num}
                  onChange={this.handleContactChange}
                />
              </label>

              <input type="submit" className="Button" value="Submit Form" />
            </div>
          </form>
        : "We've saved your information and will be in touch shortly. Thanks!"}
      </div>
    );
  }
}

export default App;

const states = [
  {
    name: "Alabama",
    code:"AL",
  },
  {
    name: "Alaska",
    code:"AK",
  },
  {
    name: "Arizona",
    code:"AZ",
  },
  {
    name: "Arkansas",
    code:"AR",
  },
  {
    name: "California",
    code:"CA",
  },
  {
    name: "Colorado",
    code:"CO",
  },
  {
    name: "Connecticut",
    code:"CT",
  },
  {
    name: "Delaware",
    code:"DE",
  },
  {
    name: "District of Columbia",
    code:"DC",
  },
  {
    name: "Florida",
    code:"FL",
  },
  {
    name: "Georgia",
    code:"GA",
  },
  {
    name: "Hawaii",
    code:"HI",
  },
  {
    name: "Idaho",
    code:"ID",
  },
  {
    name: "Illinois",
    code:"IL",
  },
  {
    name: "Indiana",
    code:"IN",
  },
  {
    name: "Iowa",
    code:"IA",
  },
  {
    name: "Kansas",
    code:"KS",
  },
  {
    name: "Kentucky",
    code:"KY",
  },
  {
    name: "Louisiana",
    code:"LA",
  },
  {
    name: "Maine",
    code:"ME",
  },
  {
    name: "Maryland",
    code:"MD",
  },
  {
    name: "Massachusetts",
    code:"MA",
  },
  {
    name: "Michigan",
    code:"MI",
  },
  {
    name: "Minnesota",
    code:"MN",
  },
  {
    name: "Mississippi",
    code:"MS",
  },
  {
    name: "Missouri",
    code:"MO",
  },
  {
    name: "Montana",
    code:"MT",
  },
  {
    name: "Nebraska",
    code:"NE",
  },
  {
    name: "Nevada",
    code:"NV",
  },
  {
    name: "New Hampshire",
    code:"NH",
  },
  {
    name: "New Jersey",
    code:"NJ",
  },
  {
    name: "New Mexico",
    code:"NM",
  },
  {
    name: "New York",
    code:"NY",
  },
  {
    name: "North Carolina",
    code:"NC",
  },
  {
    name: "North Dakota",
    code:"ND",
  },
  {
    name: "Ohio",
    code:"OH",
  },
  {
    name: "Oklahoma",
    code:"OK",
  },
  {
    name: "Oregon",
    code:"OR",
  },
  {
    name: "Pennsylvania",
    code:"PA",
  },
  {
    name: "Rhode Island",
    code:"RI",
  },
  {
    name: "South Carolina",
    code:"SC",
  },
  {
    name: "South Dakota",
    code:"SD",
  },
  {
    name: "Tennessee",
    code:"TN",
  },
  {
    name: "Texas",
    code:"TX",
  },
  {
    name: "Utah",
    code:"UT",
  },
  {
    name: "Vermont",
    code:"VT",
  },
  {
    name: "Virginia",
    code:"VA",
  },
  {
    name: "Washington",
    code:"WA",
  },
  {
    name: "West Virginia",
    code:"WV",
  },
  {
    name: "Wisconsin",
    code:"WI",
  },
  {
    name: "Wyoming",
    code:"WY",
  },
]

const endpoint_lead_insurance_types_response = {
  count: 0,
  next: 0,
  previous: 0,
  results: []
}